import React from 'react'
import {
    Card,
    Typography,
    CardContent,
    CardActions,
    Button,
    Grid,
    Box,
    ThemeProvider,
} from '@mui/material'
import { trackUmamiEvent, truncate } from './utils'
import IntegrationCardFlag from './IntegrationCardFlag'
function renderCategoryList(categories) {
    return categories
        .map((item) =>
            item.name.includes('--') ? item.name.split('--')[1] : item.name
        )
        .join(', ')
}

export const IntegrationCard = ({
    integration,
    navigate,
    marketplaceSettings,
    user,
    muiTheme,
}) => {
    const integrationSettings =
        integration.marketplaceSettings ?? integration.marketplace_settings
    const { categories } = integration
    const borderRadius = marketplaceSettings.cardBorderRadius ?? '4px'

    return (
        <ThemeProvider theme={muiTheme}>
            <Grid item key={integration.id}>
                <Card
                    sx={{
                        height: '380px',
                        width: marketplaceSettings.hasCategories
                            ? '250px'
                            : '300px',
                        borderRadius: borderRadius,
                        boxShadow: marketplaceSettings.integrationCardBoxShadow,
                        '&:hover': {
                            boxShadow:
                                marketplaceSettings.integrationCardHoverShadow,
                        },
                        '&:active': {
                            boxShadow:
                                marketplaceSettings.integrationCardClickShadow,
                        },
                        cursor: 'pointer',
                    }}
                    className={'pandium-integration-card'}
                    onClick={() => {
                        user &&
                            trackUmamiEvent(
                                `user: ${user.userName}, ${integration.name}`,
                                'View',
                                integration.id,
                                'integrations',
                                user.userID
                            )
                        navigate()
                    }}
                >
                    <Box
                        sx={{
                            backgroundColor: 'rgb(244, 244, 244)',
                            height: '150px',
                            padding: '35px',
                            margin: '10px',
                            position: 'relative',
                            borderRadius: borderRadius,
                        }}
                    >
                        <Box
                            component="img"
                            src={
                                integrationSettings.primaryLogo?.src ??
                                integrationSettings.primaryLogo ??
                                '/logos/logo-placeholder-wide.jpg'
                            }
                            alt={
                                integrationSettings.primaryLogo?.alt ??
                                `${integration.name} logo`
                            }
                            sx={{
                                objectFit: 'contain',
                                width: '100%',
                                height: '100%',
                            }}
                        />
                        {integration.flag?.id && (
                            <IntegrationCardFlag
                                name={integration.flag.name}
                                flagType={marketplaceSettings.flagType}
                                color={
                                    integration.flag?.flag_color ||
                                    integration.flag?.flagColor
                                }
                            />
                        )}
                    </Box>
                    <CardContent sx={{ height: '160px' }}>
                        <Typography
                            gutterBottom={
                                !categories || categories.length === 0
                            }
                            variant="h6"
                            sx={{ fontWeight: 600 }}
                        >
                            {integration.longName ?? integration.long_name}
                        </Typography>
                        {categories && categories.length !== 0 && (
                            <Typography variant="subtitle2" gutterBottom noWrap>
                                {renderCategoryList(categories)}
                            </Typography>
                        )}
                        <Typography
                            variant="body2"
                            color="textSecondary"
                            component="p"
                        >
                            {truncate(
                                integrationSettings.cardShortDescription,
                                110
                            )}
                        </Typography>
                    </CardContent>
                    <CardActions>
                        <Button
                            className={'pandium-card-button'}
                            size="small"
                            sx={{ fontWeight: 600 }}
                        >
                            {marketplaceSettings.learnMoreButtonLabel ??
                                (integration.name === 'la2qbo'
                                    ? ''
                                    : 'Learn more')}
                        </Button>
                    </CardActions>
                </Card>
            </Grid>
        </ThemeProvider>
    )
}

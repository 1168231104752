(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("@jsonforms/react"), require("final-form-arrays"), require("mui-rff"), require("react"), require("react-final-form"));
	else if(typeof define === 'function' && define.amd)
		define(["@jsonforms/react", "final-form-arrays", "mui-rff", "react", "react-final-form"], factory);
	else if(typeof exports === 'object')
		exports["pandipackV2"] = factory(require("@jsonforms/react"), require("final-form-arrays"), require("mui-rff"), require("react"), require("react-final-form"));
	else
		root["pandipackV2"] = factory(root["@jsonforms/react"], root["final-form-arrays"], root["mui-rff"], root["react"], root["react-final-form"]);
})(window, function(__WEBPACK_EXTERNAL_MODULE__jsonforms_react__, __WEBPACK_EXTERNAL_MODULE_final_form_arrays__, __WEBPACK_EXTERNAL_MODULE_mui_rff__, __WEBPACK_EXTERNAL_MODULE_react__, __WEBPACK_EXTERNAL_MODULE_react_final_form__) {
return 
import React from 'react'
import { Box } from '@mui/material'

export const IntegrationCardFlag = ({ name, flagType = 'topRight', color }) => {
    const flagPostionStyles = {
        topRight: {
            top: '5px',
            right: '5px',
        },
        topLeft: {
            top: '5px',
            left: '5px',
        },
        bottomRight: {
            bottom: '5px',
            right: '5px',
        },
        bottomLeft: {
            bottom: '5px',
            left: '5px',
        },
        fullWidthTop: {
            top: '5px',
            right: '5%',
            left: '5%',
        },
        fullWidthBottom: {
            bottom: '5px',
            right: '5%',
            left: '5%',
        },
    }

    return (
        <Box
            sx={{
                ...flagPostionStyles[flagType],
                backgroundColor: color ? color : 'primary.main',
                position: 'absolute',
                borderRadius: '8px',
                boxShadow: '0 2px 4px rgba(0, 0, 0, 0.5);',
                textAlign: 'center',
                '& p': {
                    fontSize: '13px',
                    color: 'white',
                    margin: '0',
                    padding: '2px 16px',
                },
            }}
        >
            <p>{name}</p>
        </Box>
    )
}

export default IntegrationCardFlag

import React, { useState } from 'react'
import { withJsonFormsControlProps } from '@jsonforms/react'

// Imported like this to reduce build sizes
import FormControl from '@mui/material/FormControl'
import Grid from '@mui/material/Grid'
import IconButton from '@mui/material/IconButton'
import InputLabel from '@mui/material/InputLabel'
import ListItemText from '@mui/material/ListItemText'
import MenuItem from '@mui/material/MenuItem'
import MuiSelect from '@mui/material/Select'
import Typography from '@mui/material/Typography'
import CheckBox from '@mui/material/Checkbox'
import Box from '@mui/material/Box'

import ArrowRightAlt from '@mui/icons-material/ArrowRightAlt'
import ExpandMore from '@mui/icons-material/ExpandMore'
import ExpandLess from '@mui/icons-material/ExpandLess'

import PropTypes from 'prop-types'
// todo: switch to react-hook-forms
import { Form, Field } from 'react-final-form'
import arrayMutators from 'final-form-arrays'
import { FieldArray } from 'react-final-form-arrays'
import { OnChange } from 'react-final-form-listeners'
import { Select } from 'mui-rff'

import { get, find, some, isEmpty, toUpper } from 'lodash-es'
import { AddButton, RemoveButton } from './MapperFieldButtons'

//    ---- Mapper Header ----
const MapperHeader = ({ mapperHeaderLabels }) => {
    return (
        <Grid
            sx={{
                backgroundColor: '#F1F4FB',
                padding: '2px',
                height: '50px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                borderRadius: '4px 4px 0px 0',
            }}
            container
            item
            xs={12}
        >
            {mapperHeaderLabels.map((label) => (
                <Grid
                    item
                    xs={6}
                    sx={{
                        pl: '45px',
                        fontWeight: 'bold',
                        fontSize: '13px',
                    }}
                    key={label}
                >
                    <Typography> {toUpper(label)} </Typography>
                </Grid>
            ))}
        </Grid>
    )
}

//       ---- Arrow ----
const Arrow = () => {
    return (
        <Grid
            sx={{
                display: 'inline-grid',
                alignItems: 'center',
                justifyContent: 'center',
            }}
            item
            xs={1}
        >
            <ArrowRightAlt
                sx={{
                    color: 'grey',
                    //these margins are controling the height of the row....
                    marginTop: '12px',
                    marginBottom: '30px',
                }}
                fontSize="large"
            />
        </Grid>
    )
}

/* ---- Select field ----
   (gets styles from parent) */

const SelectField = ({ name, label, options, multiple, handleChange, sx }) => {
    /* this component handles all the select fields in the mapper and nested rows.
       options can be strings and objects with keys (id, name)
       resulting value is the selected option / an array of selected options in case of multipleSelect. */

    const isValueString = typeof options[0] === 'string'
    const renderSelected = (selected) => {
        if (isValueString) return selected
        const renderString = multiple
            ? selected
                  .map((obj) => get(obj, 'name', null))
                  .filter((name) => name)
                  .join(', ')
            : get(selected, 'name', selected)
        return renderString.length > 30
            ? renderString.substr(0, 29) + '...'
            : renderString
    }

    return (
        <Field
            name={name}
            label={label}
            render={(props) => {
                const currentlySelected = multiple
                    ? isEmpty(props.input.value)
                        ? []
                        : props.input.value
                              .map((val) => {
                                  // Must handle dropdown of lists and strings separately.
                                  // You can't use find(options, val) on strings...
                                  // https://lodash.com/docs/4.17.15#find
                                  return typeof val === 'string'
                                      ? find(options, (currOption) => {
                                            return currOption === val
                                        })
                                      : find(options, val)
                              })
                              .filter((val) => val)
                    : props.input.value

                return (
                    <FormControl variant="outlined" sx={sx}>
                        <InputLabel> {label} </InputLabel>
                        <MuiSelect
                            multiple={multiple}
                            value={currentlySelected}
                            onChange={(event) => {
                                props.input.onChange(event.target.value)
                                handleChange(event.target.value)
                            }}
                            renderValue={renderSelected}
                            label={label}
                        >
                            {options.map((option, index) => {
                                const name = isValueString
                                    ? option
                                    : get(option, 'name', 'fixed_defaults')
                                return (
                                    <MenuItem
                                        key={name + `${index}`}
                                        value={option}
                                    >
                                        <ListItemText primary={name} />
                                        {multiple && (
                                            <CheckBox
                                                color="default"
                                                checked={
                                                    // Must handle dropdown of lists and strings separately.
                                                    // You can't use some(options, val) on strings...
                                                    // https://lodash.com/docs/4.17.15#some
                                                    typeof option === 'string'
                                                        ? some(
                                                              props.input.value,
                                                              (currValue) => {
                                                                  return (
                                                                      currValue ===
                                                                      option
                                                                  )
                                                              }
                                                          )
                                                        : some(
                                                              props.input.value,
                                                              option
                                                          )
                                                }
                                            />
                                        )}
                                    </MenuItem>
                                )
                            })}
                        </MuiSelect>
                    </FormControl>
                )
            }}
        />
    )
}

// ---- MappingCustomRow (mapper inside the expanded options) ----
//todo: is there a way to use the top_level mappingRow back here again? right now it would cause an extra nested array
const MappingCustomRow = (props) => {
    const {
        handleChange,
        advancedField,
        mapping,
        mappingIndex,
        onRemove,
        selectedFieldId,
        orderedKeys,
    } = props
    const fixedDefaults = get(advancedField, 'fixed_defaults')
    return (
        // if preselected field is present keys will be same
        orderedKeys.map((key, index) => {
            const options = get(advancedField, `items.properties.${key}.enum`)
            // if option is not string but object
            const displayOptions = options.every(
                (each) => typeof each === 'string'
            )
                ? options
                : options.filter((obj) => {
                      return Object.keys(obj)[0] === selectedFieldId
                  })[0][selectedFieldId]

            return (
                <>
                    <Grid item xs={5} key={index}>
                        {/* Grid size can also be made dynamic if needed */}
                        {fixedDefaults &&
                        mappingIndex < fixedDefaults.length ? (
                            <FormControl
                                sx={{
                                    width: '95%',
                                }}
                            >
                                <Select
                                    name={`${mapping}[${mappingIndex}].${key}.label`}
                                    variant="outlined"
                                    disabled={true}
                                    label={fixedDefaults[mappingIndex][key]}
                                    sx={{
                                        width: '95%',
                                    }}
                                >
                                    <MenuItem
                                        value={fixedDefaults[mappingIndex][key]}
                                    >
                                        {fixedDefaults[mappingIndex][key]}
                                    </MenuItem>
                                </Select>
                            </FormControl>
                        ) : (
                            <SelectField
                                name={`${mapping}.${key}`}
                                label={get(
                                    advancedField,
                                    `items.properties.${key}.label`
                                )}
                                variant="outlined"
                                options={displayOptions}
                                handleChange={handleChange}
                                disabled={false}
                                multiple={false} // making this a single option select field
                                sx={{ width: '95%' }}
                            />
                        )}
                    </Grid>
                    {/* --- Arrow  rendered if this is not last field --- */}
                    {orderedKeys[index + 1] && <Arrow />}
                    {/*// Remove row button*/}
                    {!orderedKeys[index + 1] && (
                        <Grid item xs={1}>
                            {!fixedDefaults[mappingIndex] && index > 0 && (
                                <RemoveButton onRemove={onRemove} />
                            )}
                        </Grid>
                    )}
                </>
            )
        })
    )
}

// ---- AdvancedFieldsRow ---- (the expanded options)
const AdvancedFieldsRow = (props) => {
    const {
        push,
        mapping,
        handleChange,
        advancedFields,
        values,
        selectedFieldId,
    } = props
    // advanced fields can have two kinds of fields -- one like the tags field of MC (type : string), other is a mapper with single select (advanced_field_map)
    const fields = get(advancedFields, 'properties', {})
    return Object.keys(fields).map((rowName, index) => {
        // for type: string this is a dropdown. for type: array , will be mapping fields
        const advancedField = fields[rowName]

        const optionsListAll =
            advancedField.type === 'string' ? advancedField.enum : []
        // optionsListAll is the superset of tags. This can be a single list of tags(array of strings).
        // or tags belonging to each audience indicated by an id(array of objects)
        /* [ {
                "07cad7dafc": [{
                                    "name": "red",
                                    "id": 1343956
                                },
                                {
                                    "name": "blue",
                                    "id": 1343968
                                }]
                },
                {
                "3eb6cf532c": [{
                                    "name": "cristina tag",
                                    "id": 1329775
                                }]
            }]
            */

        const optionsList = optionsListAll.every(
            (each) => typeof each === 'string'
        )
            ? optionsListAll
            : optionsListAll.filter(
                  (each) => Object.keys(each)[0] === selectedFieldId
              )[0][selectedFieldId]

        const alignment =
            get(advancedField, 'align') === 'center'
                ? 'center'
                : get(advancedField, 'align') === 'right'
                ? 'flex-end'
                : 'flex-start'

        const customFieldOrderedKeys = []
        const fieldObject = get(advancedField, 'items.properties') // to simplify

        // ordering the labels
        if (advancedField.type === 'array') {
            Object.keys(fieldObject).forEach((field) => {
                const order = get(fieldObject, `${field}.order`)
                customFieldOrderedKeys[order] = field
            })
        }

        // filtering : so order doesn't have to start at 0 .(in the YAML)
        const customFieldLabels = customFieldOrderedKeys
            .filter((key) => !isEmpty(key))
            .map((key) => get(fieldObject, `${key}.label`))

        return (
            <>
                {!isEmpty(optionsList) && (
                    <Grid
                        container
                        sx={{
                            width: '80%',
                            margin: '25px',
                        }}
                        justifyContent={alignment}
                        key={index}
                    >
                        <Grid
                            item
                            xs={4}
                            sx={{ m: '8px' }}
                            alignSelf={alignment}
                        >
                            <Box
                                sx={{
                                    color: '#9d9d9d',
                                    mb: '15px',
                                    alignItems: 'center',
                                }}
                            >
                                {' '}
                                {toUpper(advancedField.label)}{' '}
                            </Box>
                            <SelectField
                                name={`${mapping}.${rowName}`}
                                label={advancedField.label}
                                variant="outlined"
                                options={optionsList}
                                handleChange={handleChange}
                                sx={{ width: '80%' }}
                                multiple={true} // originally written with tags in mind. we can also change this to be configurable from IR
                            />
                        </Grid>
                    </Grid>
                )}
                {/*----------- mappers present in advanced fields ------ */}
                {!isEmpty(customFieldLabels) && (
                    <Grid container>
                        {customFieldLabels.map((label) => (
                            <Grid item xs={6} key={label}>
                                <span> {toUpper(label)} </span>
                            </Grid>
                        ))}

                        <FieldArray name={`${mapping}.${rowName}`}>
                            {({ fields }) =>
                                fields.map((field, index) => (
                                    <MappingCustomRow
                                        advancedField={advancedField}
                                        mapping={field}
                                        mappingIndex={index}
                                        onRemove={() => {
                                            fields.remove(index)
                                        }}
                                        handleChange={handleChange}
                                        values={values}
                                        key={index}
                                        selectedFieldId={selectedFieldId}
                                        orderedKeys={customFieldOrderedKeys.filter(
                                            (key) => !isEmpty(key)
                                        )}
                                    />
                                ))
                            }
                        </FieldArray>
                        {/* -- Add Button --*/}
                        <AddButton
                            push={push}
                            field={`${mapping}.${rowName}`}
                            value={undefined}
                            label={'  + Add Field  '}
                        />
                    </Grid>
                )}
            </>
        )
    })
}

// ---- Mapping Row (top level fields)----
const MappingRow = (props) => {
    const {
        push,
        pop,
        mapping,
        values,
        basicFields,
        basicFieldNames,
        advancedFields,
        mappingIndex,
        onRemove,
        mapperKey,
    } = props
    // in the schema  we have two parts under the mapper :
    //  mapping : this corresponds to basicFields, which are the top level mapping fields of the mapper
    // details : this corresponds to advancedFields , which can be viewed on expanding the row

    const [, setStateProperties] = useState(get(values, `${mapping}`))

    const handleChange = (value) => {
        setStateProperties(
            typeof value === 'string' ? value : value.filter((val) => val)
        )
    }
    // for checking if the advanced field are expanded
    const [expand, setExpand] = useState(false)
    return (
        <Box
            sx={{
                width: '100%',
                margin: '10px',
            }}
            key={mappingIndex}
        >
            <Grid sx={{ width: '100%' }} container spacing={2}>
                {basicFieldNames.map((field, index) => {
                    /*  The advanced field will work when last field has a value selected
                        Though the last field is a single select field, keeping the value in array
                        to make it consistent with values returned by the select field
                     */

                    const selectedField = get(values, mapping + field)
                    return (
                        // --- basic properties fields ---
                        basicFields[field].type === 'string' && (
                            <React.Fragment key={`${index} mappingRow`}>
                                <Grid
                                    item
                                    xs={4}
                                    sx={{
                                        display: 'inline-flex',
                                        margin: '8px',
                                        width: '100%',
                                    }}
                                >
                                    <SelectField
                                        name={`${mapping}.${field}`}
                                        label={get(
                                            basicFields,
                                            `${field}.select_label`,
                                            basicFields[field].label
                                        )}
                                        variant="outlined"
                                        options={basicFields[field].enum}
                                        handleChange={handleChange}
                                        multiple={
                                            basicFields[field].multiselect
                                        } // making this a single option select field
                                        sx={{ width: '100%' }}
                                    />
                                </Grid>
                                {basicFieldNames[index + 1] ? (
                                    //If not last field display arrow
                                    <Arrow />
                                ) : (
                                    // else show advanced selector if advanced fields are enabled
                                    !isEmpty(advancedFields) && (
                                        <Grid
                                            item
                                            xs={1}
                                            sx={{
                                                display: 'inline',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                mt: '25px',
                                                ml: '15px',
                                            }}
                                            key={'buttons'}
                                        >
                                            {selectedField ? (
                                                <Box
                                                    component="span"
                                                    sx={{
                                                        fontSize: '13px',
                                                        color: 'grey',
                                                        margin: '5px',
                                                    }}
                                                >
                                                    {' '}
                                                    {get(
                                                        advancedFields,
                                                        'label',
                                                        'Advanced'
                                                    )}{' '}
                                                </Box>
                                            ) : (
                                                <Box
                                                    component="span"
                                                    sx={{
                                                        fontSize: '13px',
                                                        color:
                                                            'rgba(0, 0, 0, 0.26)',
                                                        margin: '5px',
                                                    }}
                                                >
                                                    {' '}
                                                    {get(
                                                        advancedFields,
                                                        'label',
                                                        'Advanced'
                                                    )}{' '}
                                                </Box>
                                            )}
                                            {/* --- Icon for advanced field expand  --- */}
                                            <IconButton
                                                sx={{ p: 0 }}
                                                onClick={() => {
                                                    setExpand(!expand)
                                                }}
                                                disabled={!selectedField}
                                                size="large"
                                            >
                                                {expand ? (
                                                    <ExpandLess />
                                                ) : (
                                                    <ExpandMore />
                                                )}
                                            </IconButton>
                                        </Grid>
                                    )
                                )}
                                {/*  // Remove row button  */}
                                {mappingIndex > 0 &&
                                    !basicFieldNames[index + 1] &&
                                    mappingIndex > 0 && (
                                        <RemoveButton onRemove={onRemove} />
                                    )}
                                {/*// --- Advanced Fields container ---*/}
                                {!basicFieldNames[index + 1] &&
                                    !isEmpty(advancedFields) &&
                                    expand && (
                                        <Box
                                            sx={{
                                                width: '80%',
                                                backgroundColor: '#f6f6f6',
                                                mb: '10px',
                                                ml: '16px',
                                                padding: '20px',
                                            }}
                                        >
                                            {/* advanced field*/}
                                            <AdvancedFieldsRow
                                                advancedFields={advancedFields}
                                                mapping={mapping}
                                                handleChange={handleChange}
                                                values={values}
                                                push={push}
                                                pop={pop}
                                                mapperKey={mapperKey}
                                                selectedFieldId={get(
                                                    selectedField,
                                                    'id',
                                                    0
                                                )}
                                            />
                                        </Box>
                                    )}
                            </React.Fragment>
                        )
                    )
                })}
            </Grid>
        </Box>
    )
}

const AdvancedMapper = React.memo((props) => {
    const { handleChange, path, data, schema } = props

    const mapperProperties = get(schema, 'properties.mappers.items.properties')

    // in the schema  we have two parts under the mapper :
    //  top_level_fields : this corresponds to basicFields, which are the top level mapping fields of the mapper
    //  expanded_options : this corresponds to advancedFields , which can be viewed on expanding the row

    const basicFields = get(mapperProperties, 'top_level_fields.properties', {})
    const advancedFields = get(mapperProperties, 'expanded_options', {})

    // We want to show an empty state when there are no dynamic configs
    const hasDynamicConfigs = Object.keys(basicFields)
        .map((key) => basicFields[key].enum[0] !== 'placeholder')
        .every((v) => v === true)

    //Get labels for header, in order specified
    // eg :
    // "qbo_classes": {
    //                  "$ref": "#/definitions/qbo_classes",
    //                  "type": "string",
    //                  "label": "Qbo Classes",
    //                  "order": 2
    //                   }

    // orderedBasicFields = {order: field}
    let orderedBasicFields = {}

    for (const field_name of Object.keys(basicFields)) {
        // dont want to lose the field name
        basicFields[field_name].name = field_name

        // order becomes the key
        orderedBasicFields[get(basicFields[field_name], 'order', 0)] =
            basicFields[field_name]
    }

    const mapperHeaderLabels = Object.keys(orderedBasicFields)
        .sort()
        .map((order) => get(orderedBasicFields, order + '.label'))
    const OrderedBasicFieldNames = Object.keys(orderedBasicFields)
        .sort()
        .map((order) => get(orderedBasicFields, order + '.name'))

    //look for preselected advanced fields. If present add them to mapper
    let mapperKey = ''
    let fixedDefaultsData = {}
    if (!isEmpty(advancedFields)) {
        Object.keys(advancedFields.properties).forEach((key) => {
            const field = get(advancedFields.properties, `${key}`)
            // if there is another nested map
            if (get(field, 'type') === 'array') {
                mapperKey = `${key}` //advanced_field_map
                fixedDefaultsData[mapperKey] = get(field, 'fixed_defaults')
            }
        })
    }

    // eslint-disable-next-line
    const [initialValues, setInitialValues] = useState(
        hasDynamicConfigs && data ? data : fixedDefaultsData
    )
    return (
        <Form
            initialValues={initialValues}
            setInitialValues={setInitialValues}
            onSubmit={() => {}} //required
            mutators={{ ...arrayMutators }}
            render={({
                form,
                values,
                form: {
                    mutators: { push, pop },
                },
            }) => {
                return (
                    <Grid
                        container
                        sx={{
                            border: 'solid 1px lightgrey',
                            borderRadius: '4px',
                            my: '35px',
                            maxWidth: '1024px',
                        }}
                    >
                        <MapperHeader mapperHeaderLabels={mapperHeaderLabels} />
                        {hasDynamicConfigs ? (
                            <FieldArray name="mappers">
                                {({ fields }) =>
                                    fields.map((mapping, mappingIndex) => (
                                        // entire row for each mapping created by user
                                        <MappingRow
                                            basicFields={basicFields}
                                            basicFieldNames={
                                                OrderedBasicFieldNames
                                            }
                                            advancedFields={advancedFields}
                                            mapping={mapping}
                                            mappingIndex={mappingIndex}
                                            key={mappingIndex}
                                            onRemove={() =>
                                                fields.remove(mappingIndex)
                                            }
                                            values={values}
                                            form={form}
                                            push={push}
                                            mapperKey={mapperKey}
                                            pop={pop}
                                        />
                                    ))
                                }
                            </FieldArray>
                        ) : (
                            <Box sx={{ m: '10px' }}>
                                <Typography>
                                    Before configuring your mapping, please run
                                    an init sync to load your options.{' '}
                                </Typography>
                            </Box>
                        )}

                        <OnChange name="mappers">
                            {() => {
                                setInitialValues(values)
                                handleChange(path, values)
                            }}
                        </OnChange>
                        {/* -- Add Button --*/}
                        {hasDynamicConfigs && (
                            <AddButton
                                push={push}
                                field={'mappers'}
                                value={fixedDefaultsData}
                                label={'+ Add'}
                            />
                        )}
                    </Grid>
                )
            }}
        />
    )
})

AdvancedMapper.propTypes = {
    path: PropTypes.string,
    handleChange: PropTypes.func,
    schema: PropTypes.object,
}

export default withJsonFormsControlProps(AdvancedMapper)

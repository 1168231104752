import React from 'react'
import { CardMedia, Card } from '@mui/material'

import makeStyles from '@mui/styles/makeStyles'
import StylesProvider from '@mui/styles/StylesProvider'

const useHorizontalCardMediaStyles = makeStyles({
    card: {
        '& .MuiCardMedia-img': {
            objectFit: 'contain',
            height: '110px',
            padding: '10px',
            display: 'flex',
            alignItems: 'center',
            boxSizing: 'border-box',
            //todo: this shadow is lost
            // boxShadow: '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)'
        },
    },
})

export default ({ src }) => {
    const classes = useHorizontalCardMediaStyles()
    return (
        <StylesProvider injectFirst>
            {src ? (
                <Card className={classes.card}>
                    <CardMedia
                        className={'pandium-logo'}
                        src={src}
                        component="img"
                        onError={(ev) => {
                            ev.target.style = 'display:none'
                        }}
                    />
                </Card>
            ) : (
                <span> </span>
            )}
        </StylesProvider>
    )
}

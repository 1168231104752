import React from 'react'
import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'
import IconButton from '@mui/material/IconButton'
import Close from '@mui/icons-material/Close'

export const AddButton = (props) => {
    const { field, value, push, label } = props
    return (
        <Grid item xs={12} sx={{ padding: 0 }}>
            <Button
                onClick={() => {
                    push(field, value)
                }}
                sx={{
                    m: '10px',
                    color: '#626FFC',
                    fontWeight: 'bold',
                }}
            >
                {label}
            </Button>
        </Grid>
    )
}

export const RemoveButton = (props) => {
    const { onRemove } = props
    return (
        <Grid item xs={1}>
            <IconButton onClick={onRemove} size="large">
                <Close />
            </IconButton>
        </Grid>
    )
}

import { AUTH_LOGIN, AUTH_LOGOUT, AUTH_ERROR, AUTH_CHECK } from 'react-admin'
import { AUTH_GET_PERMISSIONS } from 'react-admin'

export default (keycloak) => {
    //This lib returns bespoken promises. This converts it to native ES6 promises.
    const promiseWrapper = (func) => (...rest) =>
        new Promise((resolve, reject) =>
            func(...rest)
                .then((result) => resolve(result))
                .catch((error) => reject(error))
        )

    const updateToken = promiseWrapper(keycloak.updateToken)
    const logout = promiseWrapper(keycloak.logout)

    return (type, params) => {
        // called when the user attempts to log in
        if (type === AUTH_LOGIN) {
            sessionStorage.setItem('token', keycloak.token)

            // Make it a noop as we won't be using it...
            return Promise.resolve()
        }
        // called when the user clicks on the logout button
        if (type === AUTH_LOGOUT) {
            sessionStorage.removeItem('token')
            return logout()
        }
        // called when the API returns an error
        if (type === AUTH_ERROR) {
            const { status } = params
            //TODO: remove the 500 check when api returns correct errors.
            if (status === 401 || status === 403 || status === 500) {
                //Check for auth again... if this fails than you will be logged out.
                return updateToken(3600)
                    .then(
                        (refreshed) =>
                            refreshed &&
                            sessionStorage.setItem('token', keycloak.token)
                    )
                    .catch(() => logout())
            }
            return Promise.resolve()
        }
        // called when the user navigates to a new location
        if (type === AUTH_CHECK) {
            if (
                sessionStorage.getItem('externalJWT') === 'true' &&
                !window.location.pathname.includes('/edit/connection-form')
            ) {
                return logout()
            }
            return updateToken(30).then(
                (refreshed) =>
                    refreshed && sessionStorage.setItem('token', keycloak.token)
            )
        }

        // called when the there is a authz check
        if (type === AUTH_GET_PERMISSIONS) {
            return Promise.resolve()
        }

        return Promise.reject('Unknown method')
    }
}

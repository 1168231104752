import queryString from 'query-string'
import { mapKeys, camelCase, snakeCase, isEmpty, get, isEqual } from 'lodash-es'
import { defaultLogoSvg } from './utils'
import {
    GET_LIST,
    GET_ONE,
    GET_MANY,
    GET_MANY_REFERENCE,
    CREATE,
    UPDATE,
    UPDATE_MANY,
    DELETE,
    DELETE_MANY,
    fetchUtils,
} from 'react-admin'
import { convertNestedObjectToCamel } from './utils'

export const V1RESOURCES = [
    'groups',
    'integrationlabels',
    'integrationreleases',
    'integrations',
    'organizations',
    'partners',
    'runs',
    'tenants',
    'users',
    'themeconfigs',
    'categories',
    'metrics',
    'apikeys',
]

const NO_CAMEL = ['categories', 'metrics']

export const AUTHOR = 'AUTHOR'
export const GET_AUTHOR = 'GET_AUTHOR'
export const SYNC_INITIATED = 'SYNC_INITIATED'
export const SOURCE_CONTROL_TENANT = 'SOURCE_CONTROL_TENANT'
export const UPDATE_USER_WITH_VERIFY = 'UPDATE_USER_WITH_VERIFY'
const DELETE_CONNECTOR = 'DELETE_CONNECTOR'
const ADD_CONNECTOR = 'ADD_CONNECTOR'
const GET_ONE_METRIC = 'GET_ONE_METRIC'
const UPDATE_BUILD_TOKENS = 'UPDATE_BUILD_TOKENS'

/**
 * Maps react-admin queries to a simple REST API
 *
 * The REST dialect is similar to the one of FakeRest
 * @see https://github.com/marmelab/FakeRest
 * @example
 * GET_LIST     => GET http://my.api.url/posts?sort=['title','ASC']&range=[0, 24]
 * GET_ONE      => GET http://my.api.url/posts/123
 * GET_MANY     => GET http://my.api.url/posts?filter={ids:[123,456,789]}
 * UPDATE       => PUT http://my.api.url/posts/123
 * CREATE       => POST http://my.api.url/posts/123
 * DELETE       => DELETE http://my.api.url/posts/123
 */

let subscriptions = []

export const restclient = (
    apiUrl,
    httpClient = fetchUtils.fetchJson,
    frontend
) => {
    const startSubscriber = () => {
        setInterval(() => {
            subscriptions.map((sub) => {
                console.debug(`polling ${sub.url}`)
                httpClient(sub.url, sub.options).then((response) => {
                    const { data } = convertHTTPResponseToREST(
                        response,
                        sub.type,
                        sub.resource,
                        null,
                        frontend
                    )
                    if (!isEqual(data, sub.previousData)) {
                        sub.callback({ type: 'update', payload: data })
                        sub.previousData = data
                    }
                })
            })
        }, 3000)
    }

    startSubscriber()

    /**
     * @param {String} type One of the constants appearing at the top if this file, e.g. 'UPDATE'
     * @param {String} resource Name of the resource to fetch, e.g. 'posts'
     * @param {Object} params The REST request params, depending on the type
     * @returns {Object} { url, options } The HTTP request parameters
     *
     */

    const convert_to_snake = (camel) => {
        let newobj = mapKeys(camel, function (value, key) {
            return key.includes('__')
                ? key
                : key
                      .split('.')
                      .map((nestedKey) => snakeCase(nestedKey))
                      .join('__')
        })
        return newobj
    }

    /**
     * Only snake case non-status field. Because status props are camelCased
     * @param statusField
     * @returns {*}
     */
    const convert_field_to_snake = (statusField) => {
        if (!isEmpty(statusField)) {
            const splitStatus = statusField.split('.')
            if (
                splitStatus[0] !== 'status' &&
                splitStatus[0] !== 'tenant_status'
            ) {
                // TODO: figure out how we can filter / sort on customers
                if (splitStatus[0] === 'connectedIds') {
                    return snakeCase(splitStatus[0]) + '.' + splitStatus[1]
                } else {
                    return statusField
                        .split('.')
                        .map((nestedKey) => snakeCase(nestedKey))
                        .join('__')
                }
            } else {
                return statusField.split('.').join('__')
            }
        }
        return statusField
    }

    const convertRequestForExternalApiV1 = (type, resource, params) => {
        if (resource === 'partners') {
            resource = 'organizations'
        }
        let url = ''
        const options = {}

        switch (type) {
            case GET_LIST: {
                /* * keep all params snake_case * */
                const { field, order } = params.sort

                const limit =
                    get(params, 'pagination.perPage', null) === 10
                        ? 100
                        : get(params, 'pagination.perPage', null)
                const skip = get(params, 'pagination.page', null)
                const snake_filters = convert_to_snake(params.filter)

                const query =
                    limit && skip
                        ? {
                              sort_by:
                                  convert_field_to_snake(field) + '__' + order,
                              limit: limit,
                              skip: (skip - 1) * limit,
                          }
                        : {
                              sort_by:
                                  convert_field_to_snake(field) + '__' + order,
                          }
                Object.keys(snake_filters).map(
                    (key) => (query[key] = snake_filters[key])
                )
                url = `${apiUrl}/${resource}?${queryString.stringify(query)}`
                break
            }
            case GET_ONE:
                url = `${apiUrl}/${resource}/${params.id}`
                break
            case GET_MANY: {
                // Because our api returns fully hydrated relations, we need to
                // make sure we only filter __in on the IDs of the objects
                const real_ids = params.ids.map((obj) =>
                    typeof obj === 'object' && obj.id ? obj.id : obj
                )
                const query = {
                    id__in: JSON.stringify(real_ids),
                }
                url = `${apiUrl}/${resource}?${queryString.stringify(query)}`
                break
            }
            case GET_MANY_REFERENCE: {
                /* * keep all params snake_case * */
                let { field, order } = params.sort
                const query = {
                    sort_by: convert_field_to_snake(field) + '__' + order,
                }
                Object.keys(params.filter).map(
                    (key) => (query[key] = params.filter[key])
                )
                query[params.target] = params.id
                url = `${apiUrl}/${resource}?${queryString.stringify(query)}`
                break
            }
            case UPDATE:
                url = `${apiUrl}/${resource}/${params.id}`
                options.method = 'PATCH'
                options.body = JSON.stringify(convert_to_snake(params.data))
                break
            case UPDATE_MANY:
                url = `${apiUrl}/${resource}`
                options.method = 'PATCH'
                options.body = JSON.stringify({
                    data: convert_to_snake(params.data),
                    ids: params.ids,
                })
                break
            case AUTHOR:
            case UPDATE_USER_WITH_VERIFY:
                url = `${apiUrl}/users/${params.data['userId']}/${params.data['action']}`
                options.method = 'PATCH'
                options.body = JSON.stringify(convert_to_snake(params.data))
                break
            case CREATE:
                url = `${apiUrl}/${resource}`
                options.method = 'POST'
                options.body = JSON.stringify(convert_to_snake(params.data))
                break
            case DELETE:
                url = `${apiUrl}/${resource}/${params.id}`
                options.method = 'DELETE'
                break
            case DELETE_MANY:
                console.debug('Delete Many: ', params.ids)
                url = `${apiUrl}/${resource}`
                options.method = 'DELETE'
                options.body = JSON.stringify({ ids: params.ids })
                break
            case SYNC_INITIATED:
                url = `${apiUrl.replace('v1', 'v2')}/tenants/${
                    params.data['tenantId']
                }/manual_sync?mode=${params.data['mode']}`
                options.method = 'POST'
                options.body = JSON.stringify(convert_to_snake(params.data))
                break
            case SOURCE_CONTROL_TENANT:
                url = `${apiUrl}/${resource}/${params.id}/source-control`
                options.method = 'GET'
                break
            case DELETE_CONNECTOR:
                url = `${apiUrl}/integrations/${params.integrationId}/connectors/${params.name}`
                options.method = 'DELETE'
                break
            case ADD_CONNECTOR:
                url = `${apiUrl}/integrations/${params.integrationId}/connectors`
                options.method = 'PATCH'
                options.body = JSON.stringify(params.data)
                break
            case GET_ONE_METRIC:
                url = `${apiUrl}/${params.filter.metricResource}/${params.id}/metrics`
                options.method = 'GET'
                break
            case UPDATE_BUILD_TOKENS:
                url = `${apiUrl.replace('v1', 'v0')}/author/build_secrets/${
                    params.tenantId
                }`
                options.method = 'POST'
                options.body = JSON.stringify(params.data)
                break
            default:
                throw new Error(`Unsupported fetch action type ${type}`)
        }
        return { url, options }
    }

    const convertRESTRequestToHTTP = (type, resource, params) => {
        let url = ''
        const options = {}

        if (V1RESOURCES.includes(resource)) {
            return convertRequestForExternalApiV1(type, resource, params)
        } else {
            switch (type) {
                case GET_LIST: {
                    /* * keep all params snake_case * */
                    const { field, order } = params.sort
                    const query = {
                        sort_field: convert_field_to_snake(field),
                        sort_order: order,
                        // range: JSON.stringify([
                        //     (page - 1) * perPage,
                        //     page * perPage - 1,
                        // ]),
                        filters: JSON.stringify(
                            convert_to_snake(params.filter)
                        ),
                    }
                    url = `${apiUrl}/${resource}?${queryString.stringify(
                        query
                    )}`
                    break
                }
                case GET_ONE:
                    url = `${apiUrl}/${resource}/${params.id}`
                    break
                case GET_MANY: {
                    const query = {
                        filters: JSON.stringify({ id: params.ids }),
                    }
                    url = `${apiUrl}/${resource}?${queryString.stringify(
                        query
                    )}`
                    break
                }
                case GET_MANY_REFERENCE: {
                    /* * keep all params snake_case * */
                    let { field, order } = params.sort

                    const snakedFilters = convert_to_snake(params.filter)
                    const query = {
                        sort_field: convert_field_to_snake(field),
                        sort_order: order,

                        filters: JSON.stringify({
                            ...snakedFilters,
                            [params.target]: params.id,
                        }),
                    }
                    url = `${apiUrl}/${resource}?${queryString.stringify(
                        query
                    )}`
                    break
                }
                case UPDATE:
                    url = `${apiUrl}/${resource}/${params.id}`
                    options.method = 'PATCH'
                    options.body = JSON.stringify(convert_to_snake(params.data))
                    break
                case UPDATE_MANY:
                    url = `${apiUrl}/${resource}`
                    options.method = 'PATCH'
                    options.body = JSON.stringify({
                        data: params.data,
                        ids: params.ids,
                    })
                    break
                case GET_AUTHOR:
                    url = `${apiUrl}/${resource}`
                    options.method = 'GET'
                    break
                case AUTHOR:
                case CREATE:
                    url = `${apiUrl}/${resource}`
                    options.method = 'POST'
                    options.body = JSON.stringify(convert_to_snake(params.data))
                    break
                case DELETE:
                    url = `${apiUrl}/${resource}/${params.id}`
                    options.method = 'DELETE'
                    break
                case DELETE_MANY:
                    console.debug('Delete Many: ', params.ids)
                    url = `${apiUrl}/${resource}`
                    options.method = 'DELETE'
                    options.body = JSON.stringify({ ids: params.ids })
                    break
                default:
                    throw new Error(`Unsupported fetch action type ${type}`)
            }
        }
        // author still uses v0
        if (
            resource === 'connectors' ||
            type === 'AUTHOR' ||
            type === 'GET_AUTHOR'
        ) {
            return { url: `${url.replace('v1', 'v0')}`, options }
        }
        return { url, options }
    }

    /**
     * @param {Object} response HTTP response from fetch()
     * @param {String} type One of the constants appearing at the top if this file, e.g. 'UPDATE'
     * @param {String} resource Name of the resource to fetch, e.g. 'posts'
     * @param {Object} params The REST request params, depending on the type
     * @returns {Object} REST response
     */
    const convertHTTPResponseToREST = (response, type, resource, params) => {
        const { json } = response
        let camelCasedResp = {}
        if (NO_CAMEL.includes(resource)) {
            camelCasedResp = json
        } else if (Array.isArray(json)) {
            camelCasedResp = json.map((x) => {
                return mapKeys(x, (val, key) => camelCase(key))
            })
        } else {
            camelCasedResp = mapKeys(json, (val, key) => {
                return camelCase(key)
            })
        }
        switch (type) {
            case UPDATE_MANY:
                return { data: camelCasedResp }
            case GET_LIST:
            case GET_MANY_REFERENCE:
            case GET_MANY:
                /* convert the snake cased response from backend to camel cased response
                   note : camel case of URL is going to be Url : eg- devUrl, docUrl */
                return {
                    data: camelCasedResp.map((x) => {
                        if (resource === 'integrations') {
                            //If the integration does not have a logo generate its default
                            const { marketplaceSettings, longName, id } = x
                            if (!marketplaceSettings.primaryLogo) {
                                x.marketplaceSettings.primaryLogo = defaultLogoSvg(
                                    'LOGO (2X1)',
                                    longName,
                                    id
                                )
                            }
                            if (!get(marketplaceSettings, 'secondaryLogo')) {
                                x.marketplaceSettings.secondaryLogo = defaultLogoSvg(
                                    'LOGO (1X1)',
                                    longName,
                                    id
                                )
                            }
                        }
                        if (resource === 'tenants') {
                            x.status = convertNestedObjectToCamel(x.status)
                        }
                        if (resource === 'userinfo') {
                            x.id = x.username
                        } else if (!V1RESOURCES.includes(resource)) {
                            x.id = x.name
                        }
                        return x
                    }),
                    total: Number.MAX_VALUE,
                }
            case CREATE:
                if (resource === 'ext/handshake/auth') {
                    return { data: camelCasedResp }
                }
                if (V1RESOURCES.includes(resource)) {
                    return { data: camelCasedResp }
                }
                return { data: { ...params.data, id: camelCasedResp.name } }
            case SYNC_INITIATED:
                return { data: { ...camelCasedResp } }
            case SOURCE_CONTROL_TENANT:
                return {
                    data: {
                        ...camelCasedResp,
                        status: convertNestedObjectToCamel(
                            camelCasedResp.status
                        ),
                    },
                }
            case UPDATE:
                if (resource === 'tenants') {
                    camelCasedResp.status = convertNestedObjectToCamel(
                        camelCasedResp.status
                    )
                }
                return { data: camelCasedResp || {} }
            case GET_AUTHOR:
            case AUTHOR:
                return { data: camelCasedResp || {} }
            case UPDATE_USER_WITH_VERIFY:
                return { data: { ...camelCasedResp } }
            case DELETE:
                return { data: { ...params.previousData } } //Send back an actual id? - this is needed because throws error based on resp
            case GET_ONE:
                if (resource === 'integrations') {
                    camelCasedResp.categories = camelCasedResp.categories.map(
                        (cat) => cat.id
                    )
                    camelCasedResp.tags = camelCasedResp.tags.map(
                        (cat) => cat.id
                    )
                    //If the integration does not have a logo generate its default
                    const { marketplace_settings, long_name, id } = json
                    if (!marketplace_settings.primaryLogo) {
                        response.json.marketplace_settings.primaryLogo = defaultLogoSvg(
                            'LOGO (2X1)',
                            long_name,
                            id
                        )
                    }
                    if (!marketplace_settings.secondaryLogo) {
                        response.json.marketplace_settings.secondaryLogo = defaultLogoSvg(
                            'LOGO (1X1)',
                            long_name,
                            id
                        )
                    }
                } else if (resource === 'tenants') {
                    camelCasedResp.status = convertNestedObjectToCamel(
                        camelCasedResp.status
                    )
                }
                camelCasedResp.id =
                    camelCasedResp && camelCasedResp.id
                        ? camelCasedResp.id
                        : camelCasedResp.name
                return { data: camelCasedResp }
            case GET_ONE_METRIC:
                return { data: camelCasedResp }
            default:
                camelCasedResp.id =
                    camelCasedResp && camelCasedResp.id
                        ? camelCasedResp.id
                        : camelCasedResp.name
                return { data: camelCasedResp }
        }
    }

    /**
     * @param {string} type Request type, e.g GET_LIST
     * @param {string} resource Resource name, e.g. "posts"
     * @param {Object} payload Request parameters. Depends on the request type
     * @returns {Promise} the Promise for a REST response
     */
    return (type, resource, params) => {
        if (type === 'publish') {
            console.debug('someone called dataprovider.publish()')
            return Promise.resolve({ data: null })
        }

        if (type === 'subscribe') {
            const resourceType = resource.split('/')[0]

            return httpClient(`${apiUrl}/${resource}`).then((response) => {
                const { data } = convertHTTPResponseToREST(
                    response,
                    GET_ONE,
                    resourceType,
                    null,
                    frontend
                )
                // the subscribe request expects different props
                // than the normal api calls.... the thing
                // that comes through in the position that is
                // normally "params" is the subscriber callback
                params({ payload: data })
                subscriptions.push({
                    url: `${apiUrl}/${resource}`,
                    callback: params,
                    type: GET_ONE,
                    resource: resourceType,
                    previousData: data,
                })
                console.debug(`subscribing to ${resource}`)
                return { data: null }
            })
        } else if (type === 'unsubscribe') {
            subscriptions = subscriptions.filter(
                (sub) =>
                    sub.url !== `${apiUrl}/${resource}` &&
                    sub.callback !== params
            )
            console.debug(`unsubscribing from ${resource}`)
            return Promise.resolve({ data: null })
        }

        const { url, options } = convertRESTRequestToHTTP(
            type,
            resource,
            params
        )

        return httpClient(url, options)
            .then((response) => {
                return convertHTTPResponseToREST(
                    response,
                    type,
                    resource,
                    params,
                    frontend
                )
            })
            .catch((error) => {
                // error is HttpError object
                // Error messages
                if (error.status === 409) {
                    if (
                        error.body.reason &&
                        error.body.reason === 'duplicate'
                    ) {
                        error.body = error.body.name + ' Already Exists!'
                    } else if (
                        error.body.reason &&
                        error.body.reason === 'modified'
                    ) {
                        error.body =
                            'This tenant was recently modified! Please refresh the page and try again.'
                    }
                    // This handles etcdserver request size error
                    // TODO update when postgres is set up
                } else if (error.status === 500) {
                    if (
                        error.body.reason &&
                        error.body.reason === 'Total image size is too large'
                    ) {
                        error.body = 'Total image size is too large.'
                    }
                    // This handles nginx request size error
                    // TODO update when postgres is set up
                } else if (error.status === 413) {
                    error.body = 'Total image size is too large.'
                } else if (
                    error.status === 403 ||
                    error.status === 401 ||
                    error.status === 500
                ) {
                    error.body = 'Authorization Error'
                } else if (error.status === 404) {
                    // TODO custom notifications breaks because it doesn't know how to parse this, this is the fix
                    error.body = error.body.detail
                        ? error.body.detail
                        : `${resource} not found`
                } else if (error.status === 422) {
                    error.body = error.body.detail
                        ? error.body.detail
                        : `Unprocessable request for ${resource}`
                }
                return Promise.reject({
                    message: error.body,
                    status: error.status,
                }) // rethrow it
            })
    }
}

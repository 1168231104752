import React, { useMemo, useState } from 'react'
import {
    Button,
    Dialog,
    DialogActions,
    DialogTitle,
    DialogContent,
    IconButton,
    Typography,
    Grid,
    FormControl,
    FormControlLabel,
    Checkbox,
    Box,
    Autocomplete,
    ListItem,
    TextField,
    InputAdornment,
} from '@mui/material'
import RemoveCircleOutlinedIcon from '@mui/icons-material/RemoveCircleOutlined'
import { KeyboardArrowDown, KeyboardArrowUp, Public } from '@mui/icons-material'
import { JsonFormsWrapper } from '../jsonFormComponents/JsonFormsWrapper'
import { useGetList, useDataProvider, useNotify } from 'react-admin'
import { Capitalize } from '@thefront/pandipackV2'
import { get, isEmpty as lodashIsEmpty } from 'lodash-es'
import { useFormContext } from 'react-hook-form'
import { useMutation } from 'react-query'

const AddRemoveToggle = ({ setAddRemoveConnectors, isLoading }) => {
    const [isOpen, setIsOpen] = useState(false)
    return (
        <>
            <Button disabled={isLoading} onClick={() => setIsOpen(true)}>
                Edit Connectors
            </Button>
            <Dialog open={isOpen}>
                <DialogTitle>
                    <Typography sx={{ color: 'red' }}>Are you sure?</Typography>
                </DialogTitle>
                <DialogContent>
                    <Typography color="red">
                        You may edit the connectors associated with your
                        integration, but be aware that you probably don't want
                        to do this. If your connector needs new credentials,
                        please click "reprovision" instead. Changing the
                        connectors associated with an integration will affect
                        the connection status of all associated tenants, and
                        probably requires a new integration release.
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setAddRemoveConnectors(true)}>
                        Yes, I know what I'm doing
                    </Button>
                    <Button
                        className="filledButton"
                        onClick={() => setIsOpen(false)}
                    >
                        Never Mind
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

/**
 * A wrapper to be able to render instances of the provision connector form. This allows an instance
 * for each selected connector to have it's own set of buttons/values.
 * @param props
 * @returns {*}
 */
export default ({ mode }) => {
    const form = useFormContext()
    const values = form.getValues()
    const [integrationConnectors, setIntegrationConnectors] = useState(
        values.connectors
    )
    const integrationName = values?.name
    const [addRemoveConnectors, setAddRemoveConnectors] = useState(false)

    const { data: allConectors, isLoading, error } = useGetList('connectors')

    const integrationFullConnectors = useMemo(
        () =>
            allConectors?.filter((fullCon) =>
                integrationConnectors.find(
                    (shortCon) => shortCon.name === fullCon.name
                )
            ),
        [integrationConnectors, allConectors]
    )

    // used for add connector
    const availableConnectors = useMemo(
        () =>
            allConectors?.filter(
                (fullCon) =>
                    !integrationConnectors.find(
                        (shortCon) => shortCon.name === fullCon.name
                    )
            ),
        [integrationConnectors, allConectors]
    )

    return (
        <>
            {isLoading && <div> Loading </div>}
            {error && <div> Error </div>}
            <Grid container>
                {integrationFullConnectors?.map((selectedConnector) => (
                    <ProvisionConnectorForm
                        addRemoveConnectors={addRemoveConnectors}
                        mode={mode}
                        connector={selectedConnector}
                        integrationName={integrationName}
                        integrationId={values.id}
                        key={selectedConnector?.name}
                        setIntegrationConnectors={setIntegrationConnectors}
                        showDeleteButton={integrationConnectors?.length > 1}
                        isGlobal={
                            integrationConnectors.find((connector) => {
                                return (
                                    connector.name === selectedConnector?.name
                                )
                            })?.isGlobal
                        }
                    />
                ))}
                <Grid item xs={7} display="grid" justifyItems="left">
                    {mode === 'EDIT' && addRemoveConnectors ? (
                        <>
                            <AddConnectorButton
                                availableConnectors={availableConnectors}
                                setIntegrationConnectors={
                                    setIntegrationConnectors
                                }
                                integrationId={values.id}
                            />
                            <Button
                                onClick={() => setAddRemoveConnectors(false)}
                            >
                                Stop Editing Connectors
                            </Button>
                        </>
                    ) : (
                        <AddRemoveToggle
                            setAddRemoveConnectors={setAddRemoveConnectors}
                            isLoading={isLoading}
                        />
                    )}
                </Grid>
            </Grid>
        </>
    )
}

const AddConnectorButton = ({
    setIntegrationConnectors,
    availableConnectors,
    integrationId,
}) => {
    const dataProvider = useDataProvider()
    const notify = useNotify()
    const [isOpen, setIsOpen] = useState(false)
    const [isGlobal, setIsGlobal] = useState(false)
    const [name, setName] = useState('')
    const overrideName = useMemo(
        () =>
            availableConnectors?.find((con) => con.name === name)?.metadata
                ?.override_name,
        [name, availableConnectors]
    )
    const [selectedValue, setSelectedValue] = useState({})

    const update = () => {
        setIsOpen(false)
        dataProvider
            .ADD_CONNECTOR('integrations', {
                integrationId: integrationId,
                data: { isGlobal, name, overrideName },
            })
            .then((res) => {
                setIntegrationConnectors(res.data)
                setName('')
                setSelectedValue({})
                setIsGlobal(false)
                notify('Success!')
            })
            .catch(() => notify('Error Adding Connector'))
    }

    return (
        <>
            <Button onClick={() => setIsOpen(true)}>+ Add Connector</Button>
            <Dialog open={isOpen}>
                <DialogContent>
                    <Typography color="red">
                        Adding a non-global connector to your integration will
                        dissconnect all associated tenants. Proceed with
                        caution!
                    </Typography>
                    <Box
                        component="form"
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            marginTop: '5px',
                        }}
                    >
                        <FormControlLabel
                            control={
                                <Checkbox
                                    value={isGlobal}
                                    onChange={(e) =>
                                        setIsGlobal(e.target.checked)
                                    }
                                />
                            }
                            style={{ marginTop: '20px' }}
                            label="Global"
                        />
                        <FormControl sx={{ m: 1, minWidth: 300 }}>
                            <Autocomplete
                                disableClearable
                                options={Object.values(availableConnectors)}
                                getOptionLabel={(option) =>
                                    option.label ? option.label : option.name
                                }
                                style={{
                                    width: 400,
                                    margin: '25px 25px 0 25px',
                                }}
                                onChange={(event, newValue) => {
                                    setName(newValue.name)
                                    setSelectedValue(newValue)
                                }}
                                renderOption={(props, option) => (
                                    <ListItem
                                        {...props}
                                        sx={{
                                            '&.MuiListItem-root:hover': {
                                                backgroundColor: '#D3D3D3',
                                            },
                                        }}
                                    >
                                        <div
                                            style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                height: '100%',
                                            }}
                                        >
                                            <img
                                                src={option.logoUrl}
                                                alt="option logo"
                                                style={{
                                                    height: '40px',
                                                    width: '40px',
                                                    padding: '0 10px',
                                                    objectContain: 'cover',
                                                }}
                                            />
                                            <div
                                                style={{
                                                    maxWidth: '300px',
                                                    textAlign: 'left',
                                                }}
                                            >
                                                {option.label
                                                    ? Capitalize(option.label)
                                                    : Capitalize(option.name)}
                                            </div>
                                        </div>
                                    </ListItem>
                                )}
                                renderInput={(params) => {
                                    return (
                                        <TextField
                                            {...params}
                                            variant="standard"
                                            sx={{
                                                '.MuiAutocomplete-input': {
                                                    marginBottom: '15px',
                                                    fontWeight: 'bold',
                                                },
                                            }}
                                            InputProps={{
                                                ...params.InputProps,
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <Box
                                                            style={{
                                                                display: 'flex',
                                                                alignItems:
                                                                    'center',
                                                                height: '100px',
                                                            }}
                                                        >
                                                            {!lodashIsEmpty(
                                                                selectedValue
                                                            ) && (
                                                                <img
                                                                    src={
                                                                        selectedValue?.logoUrl
                                                                    }
                                                                    alt="option logo"
                                                                    style={{
                                                                        height:
                                                                            '40px',
                                                                        width:
                                                                            '40px',
                                                                        margin:
                                                                            '0 20px 20px 20px',
                                                                    }}
                                                                />
                                                            )}
                                                        </Box>
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                    )
                                }}
                            />
                        </FormControl>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button
                        className="filledButton"
                        onClick={() => {
                            setIsOpen(false)
                            setSelectedValue({})
                        }}
                    >
                        Cancel
                    </Button>
                    <Button onClick={update} disabled={!name}>
                        Add
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

const ProvisionButton = ({
    integrationName,
    connectorName,
    openProvisionForm,
    setOpenProvisionForm,
    secretData,
    disabled,
}) => {
    const buttonStyle = {
        color: 'white',
        width: '165px',
        height: '42px',
        backgroundColor: '#626FFC',
        fontSize: '16px',
        borderRadius: '0',
        margin: '0 5px 0 5px',
        '&:disabled': {
            background: 'transparent',
        },
        marginLeft: 'auto',
    }
    const dataProvider = useDataProvider()
    const notify = useNotify()

    const { mutate: provision } = useMutation(
        () =>
            dataProvider.AUTHOR('author/provision_connector', {
                data: { integrationName, connectorName, secretData },
            }),
        {
            onSuccess: (data) => {
                console.debug('success: ', data)
                setOpenProvisionForm(false)
            },
            onError: (err) => {
                notify('Error Provisioning')
                console.debug('Error: ', err)
            },
        }
    )

    return (
        <Button
            onClick={() => {
                openProvisionForm ? provision() : setOpenProvisionForm(true)
            }}
            sx={buttonStyle}
            disabled={disabled}
        >
            {openProvisionForm ? 'Provision' : 'Reset'}
        </Button>
    )
}

const DeleteConnectorButton = ({
    name,
    label,
    integrationId,
    setIntegrationConnectors,
}) => {
    const [open, setOpen] = useState(false)
    const dataProvider = useDataProvider()
    const notify = useNotify()
    return (
        <>
            <IconButton
                onClick={() => setOpen(true)}
                sx={{ color: 'red' }}
                title="remove connector"
            >
                <RemoveCircleOutlinedIcon />
            </IconButton>
            <Dialog open={open}>
                <DialogTitle>
                    <Typography sx={{ color: 'red' }}>
                        {`Remove Connector ${label || name} From Integration`}
                    </Typography>
                </DialogTitle>
                <DialogContent>
                    <Typography>
                        Are you sure? Removing this connector from your
                        integration will affect all associated tenants.
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() =>
                            dataProvider
                                .DELETE_CONNECTOR('integrations', {
                                    integrationId: integrationId,
                                    name: name,
                                })
                                .then((res) => {
                                    setIntegrationConnectors(res.data)
                                    notify('Connector removed successfully!')
                                })
                                .catch(() => notify('Error removing connector'))
                        }
                    >
                        I'm Sure
                    </Button>
                    <Button
                        className="filledButton"
                        onClick={() => setOpen(false)}
                    >
                        CANCEL
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export const ProvisionConnectorForm = ({
    connector,
    integrationName,
    integrationId,
    mode,
    setIntegrationConnectors,
    isGlobal,
    addRemoveConnectors,
    showDeleteButton = true,
}) => {
    const [openProvisionForm, setOpenProvisionForm] = useState(
        mode === 'CREATE' ? true : false
    )
    const [jsonFormValues, setJsonFormValues] = useState({})
    const [jsonFormsErrors, setJsonFormsErrors] = useState([])

    const onJsonFormChange = (error, values) => {
        setJsonFormValues(values)
        setJsonFormsErrors(error)
    }

    const shouldRenderForm =
        get(connector, 'metadata.grant_flow') === 'web' ||
        get(connector, 'metadata.grant_flow') === 'hybrid' ||
        get(connector, 'metadata.grant_flow') === 'custom' ||
        get(connector, 'metadata.grant_flow') === 'custom-sso' ||
        (['backend', 'legacy'].includes(
            get(connector, 'metadata.grant_flow')
        ) &&
            get(connector, 'jsonrender'))

    const schema = get(connector, 'jsonform.schema')
    const uischema = get(connector, 'jsonform.uischema')

    return (
        <>
            <Grid
                item
                xs={7}
                style={{
                    display: 'flex',
                    flexFlow: 'row wrap',
                    alignItems: 'center',
                    padding: '30px',
                    margin: '30px 40px',
                    boxShadow: '0 0 0 1px #b8bbc185, 0 0 0 2px #dbdee5c7',
                    borderRadius: '3px',
                    width: '50%',
                }}
            >
                <div
                    style={{
                        display: 'flex',
                        margin: '0 10px 0 0',
                    }}
                >
                    <img
                        src={connector?.logoUrl}
                        alt=""
                        style={{
                            height: '50px',
                            width: '50px',
                            margin: '0 auto',
                            display: 'inline',
                        }}
                    />
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            margin: '0 10px',
                        }}
                    >
                        {' '}
                        {Capitalize(
                            get(connector, 'label', connector?.id)
                        )}{' '}
                    </div>
                    {isGlobal && (
                        <Public
                            style={{
                                height: '25px',
                                width: '25px',
                                margin: '12 5',
                                display: 'inline',
                            }}
                        />
                    )}
                </div>
                {!shouldRenderForm ? (
                    <div
                        style={{
                            width: '100%',
                            margin: '30px 0 0 0',
                        }}
                    >
                        {' '}
                        No provisioning required!{' '}
                    </div>
                ) : schema && openProvisionForm ? (
                    <JsonFormsWrapper
                        schema={schema}
                        uischema={uischema}
                        onJsonFormChange={onJsonFormChange}
                        data={jsonFormValues}
                        fullWidth={true}
                    />
                ) : (
                    <div></div>
                )}
                {schema && shouldRenderForm ? (
                    <ProvisionButton
                        integrationName={integrationName}
                        connectorName={connector.name}
                        openProvisionForm={openProvisionForm}
                        setOpenProvisionForm={setOpenProvisionForm}
                        secretData={jsonFormValues}
                        disabled={jsonFormsErrors.length > 0}
                    />
                ) : null}
                {Boolean(connector.secretNames) && (
                    <SecretsPopper secretNames={connector.secretNames} />
                )}
            </Grid>
            <Grid
                item
                xs={1}
                display="grid"
                alignItems="center"
                justifyItems="left"
            >
                {mode === 'EDIT' && addRemoveConnectors && showDeleteButton && (
                    <DeleteConnectorButton
                        name={connector.name}
                        label={connector.label}
                        integrationId={integrationId}
                        setIntegrationConnectors={setIntegrationConnectors}
                    />
                )}
            </Grid>
        </>
    )
}

const dialogStyles = {
    root: {
        padding: '20px',
    },
    // Need this because jsonForms adds 125% width to field label... not sure why
    dialogContent: {
        overflow: 'hidden',
    },
    link: {
        color: '#1976d2',
    },
    form: {
        width: '100%',
    },
    title: {
        display: 'flex',
        alignItems: 'baseline',
        justifyContent: 'space-between',
    },
}

const SecretsPopper = ({ secretNames }) => {
    const [openSecretsPopup, setOpenSecretsPopup] = useState(false)
    const [showAll, setShowAll] = useState(false)
    const shouldToggle = secretNames.length > 6

    return (
        <div style={{ width: '100%' }}>
            <Button
                onClick={() => setOpenSecretsPopup(true)}
                disabled={!secretNames}
                sx={{ marginTop: '4px', marginBottom: '-16px' }}
            >
                Show Secret Keys
            </Button>
            <Dialog
                open={openSecretsPopup}
                maxWidth={'sm'}
                fullWidth={true}
                sx={dialogStyles.root}
            >
                <DialogContent sx={dialogStyles.dialogContent}>
                    {secretNames.map((key, index) =>
                        showAll || index < 6 ? (
                            <Typography key={key}>{key}</Typography>
                        ) : null
                    )}
                </DialogContent>
                <DialogActions
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        flexDirection: 'row-reverse',
                    }}
                >
                    <Button
                        variant="contained"
                        sx={{
                            backgroundColor: '#626FFC',
                            color: 'white',
                        }}
                        onClick={() => setOpenSecretsPopup(false)}
                    >
                        Close
                    </Button>
                    {shouldToggle && (
                        <Button
                            endIcon={
                                showAll ? (
                                    <KeyboardArrowUp />
                                ) : (
                                    <KeyboardArrowDown />
                                )
                            }
                            onClick={() => setShowAll(!showAll)}
                        >
                            {showAll ? 'Show Less' : 'Show More'}
                        </Button>
                    )}
                </DialogActions>
            </Dialog>
        </div>
    )
}

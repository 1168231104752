import polyglotI18nProvider from 'ra-i18n-polyglot'
import englishMessage from 'ra-language-english'

/**
 *  Allows language change across React Admin.
 *  See: https://github.com/marmelab/react-admin/blob/master/docs/Translation.md
 *  Uses: https://airbnb.io/polyglot.js/#options-overview
 */
export default polyglotI18nProvider(
    () => {
        return englishMessage
    },
    'en',
    // We need this allowMissing to suppress warnings.
    // See https://github.com/marmelab/react-admin/blob/master/docs/Translation.md#silencing-translation-warnings
    { allowMissing: true }
)
